const state = () => ({
  categories: [],
  menuCategories: [],
  popularCategories: [],
  order: null,
  currentCategory: null
})

const mutations = {
  setMenuCategories (state, data) {
    state.menuCategories = data
  },
  setCurrentCategory (state, data) {
    state.currentCategory = data
  }
}

const actions = {
  async nuxtServerInit ({ dispatch }, { $backend, $filesystem, route }) {
    const api = $filesystem || $backend
    await dispatch('fetchMenuCategories', { $backend: api })
  },
  async fetchMenuCategories ({ state, commit }, { $backend }) {
    if (state.menuCategories.length === 0) {
      let categories = await $backend.menuCategories.list()
      categories = categories.map(category => ({
        ...category,
        hasChildren: state.categories.findIndex(child => child.parent === category.id) !== -1,
        children: state.categories.filter(child => child.parent === category.id)
      }))
      commit('setMenuCategories', categories)
    }
  }
}

export {
  state,
  actions,
  mutations
}
